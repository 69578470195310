module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FDA助手","short_name":"FDA助手","description":"FDA注册/认证更简单","start_url":"/?source=pwa","theme_color":"#06f","background_color":"#fff","display":"standalone","icon":"static/fdaltd.png","icons":[{"src":"/icons/icon-192x192.png","type":"image/png","sizes":"192x192"},{"src":"/icons/icon-512x512.png","type":"image/png","sizes":"512x512"},{"src":"/icons/icon-512x512.png","type":"image/png","sizes":"512x512","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4555a09c3a4210c547c9609e2e66d8d6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
